import React, { useEffect } from "react"
import './App.css';
import snowflake from './images/snowflake.png';
// import instagram from './images/instagram.jpg';
// import facebook from './images/facebook.jpg';

import aws from './images/aws.png';
import azure from './images/azure.png';
import csharp from './images/csharp.png';
import css3 from './images/css3.png';
import django from './images/django.png';
import html5 from './images/html5.png';
import javascript from './images/javascript.png';
import mysql from './images/mysql.png';
import net from './images/net.png';
import python from './images/python.png';
import react from './images/react.png';

const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "spacehere"];

function App() {

  useEffect(() => {
    generateFlipRow("COMING SOON");
    // eslint-disable-next-line
  }, []);

  function scrollLetter(targetChar, targetId) {

    const obj = document.getElementById(targetId);

    if (targetChar === " ") {
      obj.innerHTML = "&nbsp";
      return;
    }

    for (let i = 0; i < 26; i++) {
      (() => {
        setTimeout(() => {
          obj.innerHTML = alphabet[i];
        }, 70 * i);
      })(i);
      if (alphabet[i] === targetChar) { return; }
    }
  }

  function generateFlipRow(word) {
    const mainDiv = document.getElementById("some-div");

    if (mainDiv.hasChildNodes()) {
      mainDiv.replaceChildren();
    }

    const wordArray = word.split('');
    const idList = [];

    wordArray.forEach((char, index) => {
      let newElement = document.createElement("p");
      if (char === " ") {
        char = "spacehere";
      }
      newElement.setAttribute("id", char + index.toString());
      idList.push(char + index.toString());
      document.getElementById("some-div").appendChild(newElement);
    });

    wordArray.forEach((char, index) => {
      scrollLetter(char, idList[index]);
    });
  }

  return (
    <div className='main-wrapper'>
    
      <div className='header'>
          <img src = {snowflake} alt = "icon" />
          <h1>SnowMedia</h1>
      </div>


      <div className='box' />

      <div className="hanging-icon-container">
        <div className="hanging-icon-higher">
          <img src={aws} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-lower">
          <img src={azure} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-higher">
          <img src={csharp} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-lower">
          <img src={css3} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-higher">
          <img src={django} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-lower">
          <img src={html5} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-higher">
          <img src={javascript} alt="amazon-icon" />
        </div>
      </div>

      <div className='main-body'>

        <div id="some-div" className="some-div" />
        <h3>Explore new solutions with us.</h3>

        <span className="top"></span>
        <span className="right"></span>
        <span className="bottom"></span>
        <span className="left"></span>

      </div>

      <div className="hanging-icon-container">
        <div className="hanging-icon-higher">
          <img src={mysql} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-lower">
          <img src={net} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-higher">
          <img src={python} alt="amazon-icon" />
        </div>
        <div className="hanging-icon-lower">
          <img src={react} alt="amazon-icon" />
        </div>
      </div>

      {/* <div className='footer'>
        <a href = "mailto: contact@snowmedia.ca">
          <p>contact@snowmedia.ca</p>
        </a>
        <div className='social-wrapper'>
          <a href="https://www.facebook.com/clearservicesofficial" target="_blank" rel="noreferrer">
              <img src={facebook} alt='social'/>
          </a>
          <a href="https://www.instagram.com/clearservicesofficial" target="_blank" rel="noreferrer">
              <img src={instagram} alt='social'/>
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default App;
